import { Box, SxProps, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { IOrderItemsStatus, OrderItemStatus } from 'src/models/orderItemType';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import DoneIcon from '@mui/icons-material/Done';
import { useState, useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
const StatusBar = ({
  currentStatusProp,
  variant,
  handleSubmit,
  updateStatusLoading,
}: {
  currentStatusProp: IOrderItemsStatus[];
  variant: 'horizontal' | 'vertical';
  handleSubmit?: (statusList: IOrderItemsStatus[]) => void;
  updateStatusLoading?: boolean;
}) => {
  const [currentStatus, setCurrentStatus] = useState(currentStatusProp);

  useEffect(() => {
    setCurrentStatus(currentStatusProp);
  }, [currentStatusProp]);

  const STATUS_LIST: Array<OrderItemStatus> = [
    'On the way for pickup',
    'Picked up',
    'On the way for the customer',
    'Delivered',
  ];
  const IntlOption: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  };
  const statusNamesListContainerSX: SxProps<Theme> =
    variant === 'horizontal'
      ? { display: 'flex', alignItems: 'start', width: '110%', gap: 3, mt: 1 }
      : {
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 11,
          mt: 1,
          width: '100%',
        };

  const singleStatusNameAndDateSX: (index: number) => SxProps<Theme> = (index) =>
    variant === 'horizontal' ? { ml: !index ? '-2rem' : 0, width: '25%' } : { height: '25%' };

  const circleSX: (status: string) => SxProps<Theme> = (status) =>
    variant === 'horizontal'
      ? {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '50%',
          width: '2rem',
          height: '2rem',
          backgroundColor: fulfilledStatus(status) ? palette.primary.main : '#80808069',
        }
      : {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '50%',
          width: '4rem',
          height: '4rem',
          backgroundColor: fulfilledStatus(status) ? palette.primary.main : '#80808069',
          '&:hover': !fulfilledStatus(status)
            ? {
                // border: '1px solid black',
                backgroundColor: palette.primary.light,
              }
            : {},
          cursor: !fulfilledStatus(status) ? 'pointer' : 'default',
        };
  const fulfilledStatus = (status: string) => currentStatus.find((el) => el.status === status);
  const handleCircleClick = (status: OrderItemStatus) => {
    if (variant === 'horizontal') return;
    const indexOfStatusInStatusList = STATUS_LIST.indexOf(status);

    const statusStateSnapshot: IOrderItemsStatus[] = STATUS_LIST.map((stat, idx) => {
      if (idx > indexOfStatusInStatusList || currentStatus.find((e) => e.status === stat))
        return null;

      return {
        createdAt: new Date(),
        status: stat,
        updatedAt: new Date(),
        _id: '',
      };
    }).filter(Boolean);

    setCurrentStatus((prev) => [...prev, ...statusStateSnapshot]);
  };
  const { palette } = useTheme();
  const CustomDivider = ({ status }: { status: string }) =>
    variant === 'horizontal' ? (
      <Box
        sx={{
          mt: '0.9rem',
          borderTop: `2px solid ${
            fulfilledStatus(status) ? palette.primary.main : '#80808069'
          } `,
          width: '25%',
        }}
      />
    ) : (
      <Box
        sx={{
          ml: '2rem',
          borderLeft: `2px solid ${
            fulfilledStatus(status) ? palette.primary.main : '#80808069'
          } `,
          height: '5rem',
        }}
      />
    );
  const AdditionalJsxWrapper = ({ children }) =>
    handleSubmit ? (
      <Box display="flex" flexDirection="column">
        {children}
      </Box>
    ) : (
      <>{children}</>
    );
  const isDesktop = useMediaQuery('(min-width:600px)');

  return (
    <AdditionalJsxWrapper>
      <Box
        display="flex"
        flexDirection={variant === 'vertical' ? 'row' : 'column'}
        p={variant === 'vertical' ? '1rem' : 0}
        sx={{ ...(isDesktop && { width: variant === 'vertical' ? '20vw' : 'auto' }) }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'start',
            width: variant === 'vertical' ? '20%' : 'auto',
            flexDirection: variant === 'horizontal' ? 'row' : 'column',
          }}
        >
          {STATUS_LIST.map((status, idx) => (
            <>
              {idx !== 0 && <CustomDivider status={status} />}
              <Box display="flex" alignItems="center" flexDirection="column" gap={1}>
                <Box sx={circleSX(status)} onClick={() => handleCircleClick(status)}>
                  {fulfilledStatus(status) ? (
                    <DoneIcon
                      sx={{
                        color: 'white',
                        fontSize: variant === 'horizontal' ? 'medium' : 'xx-large',
                      }}
                    />
                  ) : fulfilledStatus(STATUS_LIST[idx - 1]) ? (
                    <WatchLaterIcon
                      sx={{
                        color: 'white',
                        fontSize: variant === 'horizontal' ? 'medium' : 'xx-large',
                      }}
                    />
                  ) : null}
                </Box>
              </Box>
            </>
          ))}
        </Box>
        <Box sx={statusNamesListContainerSX}>
          {STATUS_LIST.map((status, idx) => (
            <Box
              key={status + idx}
              display="flex"
              flexDirection="column"
              alignItems="center"
              sx={singleStatusNameAndDateSX(idx)}
            >
              <Typography
                sx={{ color: fulfilledStatus(status) ? palette.primary.main : 'auto' }}
                fontSize={variant === 'horizontal' ? 13 : 15}
                fontWeight="bold"
                textAlign="center"
              >
                {status}
              </Typography>
              {fulfilledStatus(status)?.createdAt && (
                <Typography fontSize={12} textAlign="center" color="primary">
                  {new Intl.DateTimeFormat('default', IntlOption).format(
                    new Date(fulfilledStatus(status)?.createdAt),
                  )}
                </Typography>
              )}
            </Box>
          ))}
        </Box>
      </Box>
      {handleSubmit && (
        <LoadingButton
          loading={updateStatusLoading}
          color="primary"
          variant="contained"
          onClick={() => {
            handleSubmit(currentStatus);
          }}
          sx={{ m: '1rem' }}
        >
          Submit Status
        </LoadingButton>
      )}
    </AdditionalJsxWrapper>
  );
};

export default StatusBar;
