/* eslint-disable max-lines */
import { Box, List, ListItem, ListItemText } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';

const ListWrapper = styled(Box)(
  ({ theme }) => `
        .MuiTouchRipple-root {
            display: none;
        }
        
        .MuiListItem-root {
            transition: ${theme.transitions.create(['color', 'fill'])};
            
            &.MuiListItem-indicators {
                padding: ${theme.spacing(1, 2)};
            
                .MuiListItemText-root {
                    .MuiTypography-root {
                        &:before {
                            height: 4px;
                            width: 22px;
                            opacity: 0;
                            visibility: hidden;
                            display: block;
                            position: absolute;
                            bottom: -10px;
                            transition: all .2s;
                            border-radius: ${theme.general.borderRadiusLg};
                            content: "";
                            background: ${theme.colors.primary.main};
                        }
                    }
                }

                &.active,
                &:active,
                &:hover {
                
                    background: transparent;
                
                    .MuiListItemText-root {
                        .MuiTypography-root {
                            &:before {
                                opacity: 1;
                                visibility: visible;
                                bottom: 0px;
                            }
                        }
                    }
                }
            }
        }
`,
);
const HeaderMenu = () => (
  <>
    <ListWrapper
      sx={{
        display: {
          xs: 'none',
          md: 'block',
        },
      }}
    >
      <List disablePadding component={Box} display="flex">
        <ListItem
          classes={{ root: 'MuiListItem-indicators' }}
          button
          component={NavLink}
          to="/management/drivers"
        >
          <ListItemText
            primaryTypographyProps={{ noWrap: true }}
            primary="Drivers Managment"
          />
        </ListItem>
        <ListItem
          classes={{ root: 'MuiListItem-indicators' }}
          button
          component={NavLink}
          to="/management/orders?status=Confirmed"
        >
          <ListItemText
            primaryTypographyProps={{ noWrap: true }}
            primary="Shipments Managment"
          />
        </ListItem>
        <ListItem
          classes={{ root: 'MuiListItem-indicators' }}
          button
          component={NavLink}
          to="/management/finances/overview"
        >
          <ListItemText
            primaryTypographyProps={{ noWrap: true }}
            primary="Finances Managment"
          />
        </ListItem>
        <ListItem
          classes={{ root: 'MuiListItem-indicators' }}
          button
          component={NavLink}
          to="/management/finances/transactions"
        >
          <ListItemText
            primaryTypographyProps={{ noWrap: true }}
            primary="Transactions History"
          />
        </ListItem>
      </List>
    </ListWrapper>
  </>
);

export default HeaderMenu;
