import { useLazyQuery } from '@apollo/client';
import { ChangeEvent, useEffect, useState } from 'react';
import { GET_DRIVER_DELIVERY_ORDERITEMS } from 'src/graphql/drivers/driversQueries';
import { IDriver } from 'src/models/IDriver';
import { IOrderItem } from 'src/models/orderItemType';
import IPaginatedResponse from 'src/types/IPaginatedResponse';
import ResponseWrapperType from 'src/types/IResponse';

const useDeliveryOrder = (driverId: string) => {
  const [getDeliveryOrders, { data, loading: deliveryOrdersLoading }] = useLazyQuery<
    ResponseWrapperType<
      'getDriverDeliveryOrders',
      'orderItems',
      IPaginatedResponse<IOrderItem>
    >
  >(GET_DRIVER_DELIVERY_ORDERITEMS, {
    variables: { driverId },
    fetchPolicy: 'network-only',
  });
  const [requestFilters, setRequestFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: '',
    sort: 1,
    filter: '',
    driverId,
  });
  const orderItemData = data?.getDriverDeliveryOrders.orderItems;

  useEffect(() => {
    getDeliveryOrders({
      variables: {
        page: requestFilters.page,
        limit: requestFilters.limit,
        sort: requestFilters.sort,
        sortBy: requestFilters.sortBy,
        filter: requestFilters.filter,
        driverId,
      },
    });
  }, [getDeliveryOrders, requestFilters]);
  const handlePageChange = (event, newPage: number): void => {
    setRequestFilters({ ...requestFilters, ...{ page: newPage } });
  };
  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setRequestFilters({ ...requestFilters, ...{ limit: +event.target.value } });
  };
  return {
    handlePageChange,
    handleLimitChange,
    orderItemData,
    requestFilters,
    deliveryOrdersLoading,
  };
};
export default useDeliveryOrder;
