/* eslint-disable max-lines */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { format } from 'date-fns';
import Card from '@mui/material/Card/';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PersonIcon from '@mui/icons-material/Person';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {
  Slide,
  Tooltip,
  CardHeader,
  Link,
  Collapse,
  ListItemButton,
  ListItem,
  List,
  Container,
  Pagination,
} from '@mui/material';
import { IDriver } from 'src/models/IDriver';

import StatusBar from 'src/content/applications/Orders/OrderCard/StatusBar';
import useDriverProfile from '../useDriverProfile';
import usePickupOrder from './usePickupOrder';
import SuspenseLoader from 'src/components/SuspenseLoader';

const PickUpOrder = ({ driverId }: { driverId: string }) => {
  const {
    buyerDetailsVisibility,
    setBuyerDetailsVisibility,
    handleOrderClick,
    expandedOrderId,
  } = useDriverProfile();
  const {
    handleLimitChange,
    handlePageChange,
    orderItemData,
    requestFilters,
    pickupOrdersLoading,
  } = usePickupOrder(driverId);
  return (
    <Container maxWidth="xl" style={{ paddingTop: '3rem', paddingBottom: '3rem' }}>
      <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        {pickupOrdersLoading ? (
          <SuspenseLoader isTableLoader />
        ) : (
          orderItemData?.items.map((order) => (
            <>
              <Grid item xs={2} sm={4} md={6} key={order._id}>
                <Slide direction="up" in timeout={500}>
                  <Card>
                    <CardHeader
                      subheader={
                        <Box px={1} display="flex" justifyContent="space-between">
                          <Typography sx={{ lineHeight: 1.8 }} variant="overline">
                            {order?.orderItemCode}
                          </Typography>
                          <Typography variant="caption">
                            {format(new Date(order?.createdAt), 'dd/MM/yyyy HH:mm')}
                          </Typography>
                        </Box>
                      }
                      sx={{
                        margin: 0,
                        padding: 1,
                        textAlign: 'right',
                      }}
                    />
                    <CardContent>
                      <Grid container spacing={1} flexDirection="column">
                        <Box px={3} py={1}>
                          <Typography variant="subtitle2">
                            <Grid item display="flex" width="100%">
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'start',
                                }}
                              >
                                <Box display="flex" alignItems="center" gap={1}>
                                  <Box
                                    sx={{
                                      borderRadius: '50%',
                                      width: '1.6rem',
                                      height: '1.6rem',
                                      backgroundColor: '#ffa500a8',
                                    }}
                                  />
                                  <Typography fontSize={13} fontWeight="bold">
                                    To: {order?.billingAddress?.city}-
                                    {order?.billingAddress?.address}-
                                    {order?.billingAddress?.house}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>

                            <Grid item p={3}>
                              <StatusBar
                                variant="horizontal"
                                currentStatusProp={order.orderItemStatus}
                              />
                            </Grid>

                            <Grid item display="flex" width="100%">
                              <Tooltip
                                title="Click to view shipment informations"
                                placement="left"
                              >
                                <Link
                                  href={order?.shipmentQrCode}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Shipment Information
                                </Link>
                              </Tooltip>
                            </Grid>
                            <Grid item display="flex" width="100%" justifyContent="flex-end">
                              <Button
                                type="submit"
                                variant="outlined"
                                onClick={() => handleOrderClick(order._id)}
                                sx={{
                                  margin: '5px',
                                }}
                              >
                                Details
                              </Button>
                            </Grid>
                          </Typography>
                        </Box>
                      </Grid>
                    </CardContent>

                    <Collapse
                      title="Shipment details"
                      in={expandedOrderId === order._id}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          mx: '1rem',
                          mb: '1rem',
                          gap: '1rem',
                        }}
                      >
                        <Card>
                          <Grid item display="flex" margin={2}>
                            <ListItemButton
                              onClick={() =>
                                setBuyerDetailsVisibility(!buyerDetailsVisibility)
                              }
                            >
                              <Typography
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                Buyer Details
                              </Typography>
                            </ListItemButton>
                          </Grid>

                          <Collapse
                            title="Buyer details"
                            in={buyerDetailsVisibility}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List sx={{ pt: 0 }}>
                              <ListItem>
                                <Typography
                                  variant="body1"
                                  fontSize="13px"
                                  sx={{ display: 'flex', alignItems: 'center' }}
                                >
                                  <Tooltip title="UserName" placement="left">
                                    <PersonIcon />
                                  </Tooltip>
                                  <Typography
                                    sx={{
                                      marginLeft: '2px',
                                    }}
                                  >
                                    {order?.billingAddress?.fullName}
                                  </Typography>
                                </Typography>
                              </ListItem>
                              <ListItem>
                                <Typography
                                  variant="body1"
                                  fontSize="13px"
                                  sx={{ display: 'flex', alignItems: 'center' }}
                                >
                                  <Tooltip title="Phone" placement="left">
                                    <LocalPhoneIcon />
                                  </Tooltip>

                                  <Typography
                                    sx={{
                                      marginLeft: '2px',
                                    }}
                                  >
                                    {order?.billingAddress?.phone}
                                  </Typography>
                                </Typography>
                              </ListItem>
                              <ListItem>
                                <Typography
                                  variant="body1"
                                  fontSize="13px"
                                  sx={{ display: 'flex', alignItems: 'center' }}
                                >
                                  <Tooltip title="Address" placement="left">
                                    <LocationOnIcon />
                                  </Tooltip>

                                  <Typography
                                    sx={{
                                      marginLeft: '2px',
                                    }}
                                  >
                                    {order?.billingAddress?.city}-{order?.billingAddress?.area}
                                    -{order?.billingAddress?.address}-
                                    {order?.billingAddress?.house}
                                  </Typography>
                                </Typography>
                              </ListItem>
                            </List>
                          </Collapse>
                        </Card>
                        <Card />
                      </Box>

                      <Box
                        p={3}
                        pt={0}
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                      />
                    </Collapse>
                  </Card>
                </Slide>
              </Grid>
            </>
          ))
        )}
        <Box display="flex" width="100%" justifyContent="center" pt={5}>
          <Pagination
            variant="outlined"
            shape="rounded"
            size="large"
            count={orderItemData?.totalPages}
            color="primary"
            onChange={handlePageChange}
            page={requestFilters.page}
          />
        </Box>
      </Grid>
    </Container>
  );
};

export default PickUpOrder;
