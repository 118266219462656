import axios from 'axios';

export const COURRIER_BACKEND_API = (token: string) =>
  axios.create({
    headers: {
      authorization: `Bearer ${token || ''}`,
    },
    baseURL:
      process.env.REACT_APP_ENVIRONMENT === 'production'
        ? process.env.REACT_APP_REST_API
        : 'http://localhost:3050/API',
  });
