/* eslint-disable max-lines */
/* eslint-disable react/jsx-no-undef */

import { Helmet } from 'react-helmet-async';
import Footer from 'src/components/Footer';
import { Grid, Container, Card, Dialog, Box, Typography, Avatar, Button } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import useMediaQuery from '@mui/material/useMediaQuery';
import SuspenseLoader from 'src/components/SuspenseLoader';
import useDriverProfile from './useDriverProfile';
import DriverForm from '../AddDriverFormPage/DriverForm';
import DriverInfo from './DriverInfo';
import PickUpOrder from './PickUpOrder';
import DeliveryOrder from './DeliveryOrder';

const ManagementDriverProfile = () => {
  const {
    driver,
    defaultValues,
    credentials,
    formMethods,
    handleSubmit,
    register,
    control,
    setValue,
    isRegisterDriverLoading,
    setDisplayUpdatesCard,
    displayUpdatesCard,
    cities,
    handleNextClick,
    setCredentials,
    handleSubmitCredentials,
    handleCloseDriversModal,
    currentDisplay,
    setCurrentDisplay,
  } = useDriverProfile();
  const isDesktop = useMediaQuery('(min-width:600px)');

  return (
    <>
      <Helmet>
        <title>Driver Details</title>
      </Helmet>

      <>
        <Container sx={{ mt: 2 }}>
          <Grid direction="row" spacing={3} justifyContent="center" alignItems="stretch">
            <Grid item xs={6} md={8}>
              {driver ? (
                <Card
                  sx={{
                    flexGrow: 1,
                    backgroundColor: 'rgba(255,255,255,1)',
                    marginRight: 'auto',
                    marginLeft: 'auto',
                    marginTop: '5rem',
                    padding: isDesktop ? '20px' : 0,
                  }}
                >
                  <Grid container flexDirection="column">
                    <Grid item marginLeft="10px">
                      <Box display="flex" flexDirection={isDesktop ? 'row' : 'column'}>
                        <Avatar
                          alt={driver?.name}
                          sx={{
                            borderRadius: '50rem',
                            margin: '1rem',
                            width: '10rem',
                            height: '10rem',
                          }}
                          src={
                            driver?.avatar
                              ? driver?.avatar
                              : '/static/images/avatars/defaultAvatar.png'
                          }
                        />{' '}
                        <Box display="flex">
                          <Typography variant="h1" marginLeft={2} sx={{ alignSelf: 'center' }}>
                            {driver?.name}
                          </Typography>

                          <Button
                            variant="outlined"
                            sx={{ ml: 4, height: '2,5rem', alignSelf: 'center' }}
                            onClick={() => setDisplayUpdatesCard(true)}
                          >
                            Update Infos
                          </Button>
                        </Box>
                      </Box>
                    </Grid>

                    <Card>
                      <Box
                        display="flex"
                        marginY={2}
                        marginX={isDesktop ? 2 : 0}
                        justifyContent="center"
                        gap="2rem"
                      >
                        <Box display="flex" alignItems="center">
                          <Button
                            sx={{ width: isDesktop ? '10rem' : '6rem', height: '4rem' }}
                            size="medium"
                            variant={currentDisplay === 'details' ? 'contained' : 'outlined'}
                            onClick={() => setCurrentDisplay('details')}
                          >
                            Details
                          </Button>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Button
                            sx={{ width: isDesktop ? '10rem' : '6rem', height: '4rem' }}
                            size="medium"
                            variant={
                              currentDisplay === 'deliveryOrder' ? 'contained' : 'outlined'
                            }
                            onClick={() => setCurrentDisplay('deliveryOrder')}
                          >
                            Delivery Order
                          </Button>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Button
                            size="medium"
                            sx={{ width: isDesktop ? '10rem' : '6rem', height: '4rem' }}
                            variant={
                              currentDisplay === 'pickUpOrder' ? 'contained' : 'outlined'
                            }
                            onClick={() => setCurrentDisplay('pickUpOrder')}
                          >
                            Pick Up Order
                          </Button>
                        </Box>
                      </Box>
                      {currentDisplay === 'details' && (
                        <Box
                          px={isDesktop ? 2 : 0}
                          py={2.8}
                          display="flex"
                          sx={{ width: '100%' }}
                        >
                          <DriverInfo driver={driver} />
                        </Box>
                      )}
                      {currentDisplay === 'deliveryOrder' && (
                        <Box
                          px={isDesktop ? 2 : 0}
                          py={2.8}
                          display="flex"
                          sx={{ width: '100%' }}
                        >
                          <DeliveryOrder driverId={driver._id} />
                        </Box>
                      )}
                      {currentDisplay === 'pickUpOrder' && (
                        <Box
                          px={isDesktop ? 2 : 0}
                          py={2.8}
                          display="flex"
                          sx={{ width: '100%' }}
                        >
                          <PickUpOrder driverId={driver._id} />
                        </Box>
                      )}
                    </Card>
                  </Grid>
                </Card>
              ) : (
                <SuspenseLoader />
              )}
            </Grid>

            {displayUpdatesCard && (
              <Dialog
                maxWidth="xl"
                open={displayUpdatesCard}
                onClose={handleCloseDriversModal}
              >
                <FormProvider {...formMethods}>
                  <Grid item xs={12} md={8}>
                    <Card style={{ padding: '3rem', ...(!isDesktop && { padding: 0 }) }}>
                      {/* <CardHeader title="Driver Informations" />
                      <Grid item xs={12} p={1} /> */}
                      <DriverForm
                        isUpdate={!!defaultValues._id}
                        cities={cities}
                        setValue={setValue}
                        control={control}
                        register={register}
                        handleNextClick={handleNextClick}
                        isRegisterDriverLoading={isRegisterDriverLoading}
                        credentials={credentials}
                        setCredentials={setCredentials}
                        handleSubmitCredentials={handleSubmit(handleSubmitCredentials)}
                      />
                    </Card>
                  </Grid>
                </FormProvider>
              </Dialog>
            )}
          </Grid>
        </Container>
        <Footer />
      </>
    </>
  );
};

export default ManagementDriverProfile;
