import { AxiosRequestConfig } from 'axios';
import { getFormData } from 'src/helpers/getFormData';
import { IDriverCredential } from 'src/models/IDriver';
import { COURRIER_BACKEND_API } from './api';

export const addDriver = async (values: IDriverCredential) => {
  const reqBody = new FormData();
  const token = localStorage.getItem('token');
  getFormData(reqBody, values);
  const config = {
    headers: {
      ...COURRIER_BACKEND_API(token).defaults.headers,
      'Content-Type': 'multipart/form-data',
    },
  };

  const response = await (
    await COURRIER_BACKEND_API(token).post<{ message: string; status: string }>(
      'add-driver',
      reqBody,
      config as unknown as AxiosRequestConfig,
    )
  ).data;

  return response;
};
export const updateDriver = async (_id: string, values: IDriverCredential) => {
  const reqBody = new FormData();
  const token = localStorage.getItem('token');
  getFormData(reqBody, { driverId: _id, ...values });
  const config = {
    headers: {
      ...COURRIER_BACKEND_API(token).defaults.headers,
      'Content-Type': 'multipart/form-data',
    },
  };

  const response = await (
    await COURRIER_BACKEND_API(token).put<{ message: string; status: string }>(
      'update-driver',
      reqBody,
      config as unknown as AxiosRequestConfig,
    )
  ).data;

  return response;
};
