import { useLazyQuery, useQuery } from '@apollo/client';
import { FC, useState, createContext } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { VERIFY_TOKEN } from 'src/graphql/courrier/courrierQueries';
import { IUser } from 'src/models/IUser';
import ResponseWrapperType from 'src/types/IResponse';
type UserContextType = {
  currentCourrier: IUser;
  handleUpdateCurrentCourrier: ({ courrier }: IUser) => void;
  refreshCurrentUser: () => any;
};

export const UserContext = createContext<UserContextType>({} as UserContextType);

export const UserProvider: FC = ({ children }) => {
  const [currentCourrier, setCurrentCourrier] = useState<IUser>(null);
  const navigate = useNavigate();
  const handleUpdateCurrentCourrier = (courrier: IUser) => {
    setCurrentCourrier(courrier);
  };

  useQuery<ResponseWrapperType<'verifyToken', 'user', IUser>>(VERIFY_TOKEN, {
    onCompleted: (res) => {
      if (res.verifyToken.status !== 200) {
        toast(res.verifyToken?.message, {
          type: 'error',
        });
        localStorage.removeItem('token');
        navigate('/signin');
      }
      if (!res.verifyToken.user?._id) navigate('/signin');

      setCurrentCourrier(res.verifyToken?.user);
    },
  });
  const [refreshCurrentUser] = useLazyQuery<ResponseWrapperType<'verifyToken', 'user', IUser>>(
    VERIFY_TOKEN,
    {
      onCompleted: (res) => {
        if (res.verifyToken.status !== 200) {
          toast(res.verifyToken?.message, {
            type: 'error',
          });
          localStorage.removeItem('token');
          navigate('/signin');
        }

        if (!res.verifyToken?.user?._id) navigate('/signin');

        setCurrentCourrier(res.verifyToken?.user);
      },
    },
  );
  const handleRefreshCurrentUser = () => {
    refreshCurrentUser({
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        setCurrentCourrier(res.verifyToken?.user);
      },
    });
  };

  return (
    <UserContext.Provider
      value={{
        currentCourrier,
        handleUpdateCurrentCourrier,
        refreshCurrentUser: handleRefreshCurrentUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
